import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import Menu from "../components/menu";
import { getProvince, getShopProvinces } from "../functions/getProvince";
import { getShopType } from "../functions/uploadReceipt";
import { getShopOnlineType } from "../functions/uploadReceipt";
import { getEventShop } from "../functions/uploadReceipt";
import { getReceiptProduct } from "../functions/uploadReceipt";
import { addMeetAndGreet } from "../functions/uploadMeetAndGreet";
import { LoadingOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { uploadImage } from "../functions/uploadImg";
import UploadReceipt from "../components/uploadReceipt";
import { getCreditMeetAndGreet } from "../functions/uploadMeetAndGreet";

import { InputNumber, Select, Space, Radio, Modal, Col, Row } from "antd";
const { confirm } = Modal;

const Upload_meetandgreet = ({ history }) => {
  const { user } = JSON.parse(window.localStorage.getItem("auth"));
  const token = user.token;
  const addressStatus = localStorage.getItem("addressStatus");
  const campaignType = localStorage.getItem("campaignType");
  const [type, setType] = useState("");
  const [img, setImg] = useState("");
  const [shop_id, setShop_id] = useState(0);
  const [price, setPrice] = useState(0);
  const [date, setDate] = useState("");
  const [other_shop, seOther_shop] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const maxDate = new Date().toISOString().split("T")[0];
  const [selectedProductTypes, setSelectedProductTypes] = useState([]);
  const [selectedType, setSelectedType] = useState("cat"); // Default type

  const showPromiseConfirm = () => {
    let msgTitle = " ";
    let msgDesc = " ";
    let path = " ";

    // console.log("profireStatus",profireStatus)
    // console.log("addressStatus",addressStatus)
    // console.log("petStatus",petStatus)
    if (addressStatus === "0") {
      msgTitle = "เพื่อรับสิทธิประโยชน์จากทางแบรนด์";
      msgDesc = "กรุณาเพิ่มข้อมูลที่อยู่ให้ครบถ้วน";
      path = "/add_address";

      localStorage.setItem("Page", "campaign");
    }

    confirm({
      title: msgTitle,
      icon: <InfoCircleOutlined />,
      content: msgDesc,
      onOk() {
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          history.push(path);
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };
  const handleTypeChange = (e) => {
    setType(e.target.value);
    if (e.target.value === "other") {
      setShop_id(5);
    }
  };

  const handleRadioChange = (e) => {
    const newType = e.target.value;
    setSelectedType(newType);
    loadReceiptProduct(newType);
  };

  const handleChange = (value, type) => {
    // Preserve previously selected products
    const previousSelectedProducts = selectedProducts.filter((product) =>
      value.includes(product.id)
    );

    // Find and add newly selected products
    const newSelectedProducts = value
      .map((productId) => {
        const selectedProduct = ReceiptProduct.find(
          (item) => item.id === productId
        );
        if (selectedProduct) {
          return { id: selectedProduct.id, name: selectedProduct.name };
        } else {
          return null; // or handle the case when product is not found
        }
      })
      .filter(Boolean);

    // Combine previously selected and newly selected products
    const combinedSelectedProducts = [
      ...previousSelectedProducts,
      ...newSelectedProducts,
    ];

    // Remove duplicates from combinedSelectedProducts
    const uniqueSelectedProducts = combinedSelectedProducts.filter(
      (product, index, self) =>
        index === self.findIndex((p) => p.id === product.id)
    );

    setSelectedProducts(uniqueSelectedProducts);

    const updatedTypes = new Set(selectedProductTypes); // Create a copy of existing types
    updatedTypes.add(type); // Add the new type
    setSelectedProductTypes(updatedTypes);
  };

  useEffect(() => {
    if (addressStatus === "0") {
      // let type = petStatus === "0" ? "pet" : "address";
      showPromiseConfirm();
    } else {
      localStorage.removeItem("Page");
    }

    loadProvince();
    loadShopType();
    loadShopOnlineType();
    loadEventShop();
    loadReceiptProduct(selectedType);
    loadCreditMeetAndGreet();
    setPrice("");
  }, []);

  useEffect(() => {
    loadReceiptProduct(selectedType);
  }, [selectedType]);

  const [ShopType, setShopType] = useState([]);
  const loadShopType = async () => {
    getShopType(token).then((res) => {
      setShopType(res.data.data);
    });
  };

  const [ShopOnlineType, setShopOnlineType] = useState("");
  const loadShopOnlineType = async () => {
    getShopOnlineType(token).then((res) => {
      setShopOnlineType(res.data.data);
      setShop_id(res.data.data[0].id);
    });
  };

  const [EventShop, setEventShop] = useState("");
  const loadEventShop = async () => {
    getEventShop(token).then((res) => {
      if (res.data.data.length > 0) {
        setEventShop(res.data.data);
        setShop_id(res.data.data[0].id);
      } else {
        setEventShop([]);
        setShop_id(0);
      }
    });
  };

  //ดึงข้อมุลจังหวัดรวม
  const [provinces, setProvinces] = useState([]);
  const loadProvince = async () => {
    getProvince(token).then((res) => {
      setProvinces(res.data.data);
    });
  };

  const [CreditMeetAndGreet, setCreditMeetAndGreet] = useState();

  const loadCreditMeetAndGreet = async () => {
    try {
      const res = await getCreditMeetAndGreet(token);
      if (res.data && res.data.data) {
        setCreditMeetAndGreet(res.data.data);
        console.log("res.data.data : ", res.data.data);
      } else {
        console.error("Error: data or data.data is undefined");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  //สร้างตัวแปรไว้ส่งค่าจังหวัดที่เลือก
  const [province, setProvince] = useState("");

  //ตัวแปรไว้เก็บค่าชื่อร้านค้าในจังหวัดทั้งหมด
  const [shopProvinces, setShopProvinces] = useState([]);

  //ฟังชัน onChang เมื่อเลือกจังหวัดแล้วจะส่งค่าไอดีมาเก็บไว้ที่ setProvince
  const handleProvince = (e) => {
    setProvince(e.target.value);

    //ส่งค่า api ของ id จังหวัดไปค้นหาชื่อร้านค่า (e.target.value)
    getShopProvinces(token, e.target.value).then((res) => {
      setShopProvinces(res.data.data);
    });
  };

  const [ReceiptProduct, setReceiptProduct] = useState([]);
  const loadReceiptProduct = async (type) => {
    try {
      const res = await getReceiptProduct(token, type);
      setReceiptProduct(res.data.data);
    } catch (error) {
      console.error("Error loading receipt products:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("selectedProducts", selectedProducts);
    // console.log("selectedProductTypes", selectedProductTypes);
    let pet_type;
    let product_id;

    if (addressStatus === "0") {
      // let type = petStatus === "0" ? "pet" : "address";
      showPromiseConfirm();
      return;
    }

    if (selectedProductTypes.size === 1) {
      pet_type = selectedProductTypes.values().next().value;
    } else if (selectedProductTypes.size > 1) {
      pet_type = "both";
    } else {
      pet_type = "unknown";
    }
    if (price === "") {
      alert("กรุณาใส่ยอดเงิน");
    }
    if (campaignType === "gold" && price < 500) {
      alert("ยอดเงินขั้นต่ำ 500 บาท");
      return;
    } else if (campaignType === "meet" && price < 1000) {
      alert("ยอดเงินขั้นต่ำ 1,000 บาท");
      return;
    }
    if (date === "") {
      alert("กรุณาใส่วันที่ซื้อ");
    }
    product_id = JSON.stringify(selectedProducts);
    if (img === "") {
      alert("กรุณาอัพโหลดรูปภาพ");
    }

    if (type === "online" && shop_id === 0) {
      alert("กรุณาเลือกร้านค้า");
    }

    if (type === "onsite" && shop_id === 0) {
      alert("กรุณาเลือกร้านค้า");
    }

    if (type === "event" && shop_id === 0) {
      alert("กรุณาเลือก Event");
    }

    setLoading(true);
    let data = new FormData();
    data.append("file", img);
    data.append("upload_preset", process.env.REACT_APP_PRESET_NAME);
    data.append("cloud_name", process.env.REACT_APP_CLOUD_NAME);
    data.append("folder", "uat-moochie");

    let authtoken = token;

    try {
      const resp = await uploadImage(data);

      let receipt = {
        img: resp.data.secure_url,
        shop_id: shop_id,
        price: price,
        pet_type: selectedType,
        product_id: product_id,
        date: date,
        other_shop: other_shop,
      };

      if (campaignType === "gold") {
        receipt.receipt_type = "gold";
      }

      console.log(" SUTMIT : ", receipt);
      addMeetAndGreet(authtoken, receipt)
        .then((res) => {
          setLoading(false);

          const page = "select_campaign";
          window.location.href = `alertSuscess/${page}`;
        })
        .catch((err) => {
          setLoading(false);
          console.log("err: ", err);
          alert("บันทึกไม่สำเร็จ");
        });
    } catch (error) {
      setLoading(false);
      console.log("ERROR :", error);
    }
  };

  const onChange = (value) => {
    setPrice(value);
  };

  return (
    <div>
      <div className="backgroundUpload">
        <div className=" d-flex align-items-center register_cam">
          <div
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
              marginLeft: "10px",
            }}
            className="dog_icon"
          ></div>
          <b className="Header_register">&nbsp;&nbsp;ส่งใบเสร็จ&nbsp;&nbsp;</b>

          <div
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
            }}
            className="cat_icon"
          ></div>
        </div>
        <center>
          {campaignType === "meet" ? (
            <b style={{ color: "#FF0099", fontSize: "16px" }}>
              Moochie Pet Food X Ling Ling Kwong
            </b>
          ) : campaignType === "gold" ? (
            <b style={{ color: "#FF0099", fontSize: "20px" }}>Gold</b>
          ) : null}
        </center>
        <br></br>
        <div className="lucky_current_point">
          <div
            style={{ color: "#ff3a89", textAlign: "center", fontSize: "16px" }}
          >
            สิทธิ์ของฉัน
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: '10px'
            }}
          > */}
          {/* <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/8893f97892e08b36b33b80d486605821e51076038917fa9baa1edaed4eccae92?apiKey=e95df7cd88704225a9c4058d8e5f379d&"
              style={{ marginRight: "30px", }}
            /> */}

          {/* <span style={{ marginRight: "15px", transform: "scale(1.5)", marginRight: "30px" }}> */}
          {/* 12314 {point}{" "} */}

          {/* {CreditMeetAndGreet !== undefined ? (
                <p>{CreditMeetAndGreet.toLocaleString()}</p>
              ) : (
                <p>0</p>
              )}

            </span> */}
          {/* <span>สิทธิ์</span> */}
          {/* </div> */}
          <Row>
            <Col span={8}>
              <div>
                <div>
                  ทองคำ 1 กรัม <br></br>
                  <b style={{ color: "#fff" }}>.</b>
                </div>
                <span
                  style={{
                    marginRight: "15px",
                    transform: "scale(1.5)",
                    marginRight: "30px",
                  }}
                >
                  {/* 12314 {point}{" "} */}
                  <br></br>
                  {CreditMeetAndGreet !== undefined ? (
                    <p>{CreditMeetAndGreet?.credit_gold.toLocaleString()}</p>
                  ) : (
                    <p>0</p>
                  )}
                </span>
              </div>
            </Col>
            <Col span={8}>
              <div>
                <div>
                  Special Event <br></br>ใบเสร็จ 1,000
                </div>
                <span
                  style={{
                    marginRight: "15px",
                    transform: "scale(1.5)",
                    marginRight: "30px",
                  }}
                >
                  {/* 12314 {point}{" "} */}
                  <br></br>
                  {CreditMeetAndGreet !== undefined ? (
                    <p>{CreditMeetAndGreet?.credit_normal.toLocaleString()}</p>
                  ) : (
                    <p>0</p>
                  )}
                </span>
              </div>
            </Col>
            <Col span={8}>
              <div>
                <div>
                  Special Event <br></br>ใบเสร็จ 3,000
                </div>
                <span
                  style={{
                    marginRight: "15px",
                    transform: "scale(1.5)",
                    marginRight: "30px",
                  }}
                >
                  {/* 12314 {point}{" "} */}
                  <br></br>
                  {CreditMeetAndGreet !== undefined ? (
                    <p>{CreditMeetAndGreet?.credit_vvip.toLocaleString()}</p>
                  ) : (
                    <p>0</p>
                  )}
                </span>
              </div>
            </Col>
          </Row>
        </div>
        <Form onSubmit={handleSubmit}>
          <br></br>
          <center>
            <Form.Label className="">อัปโหลดภาพใบเสร็จ</Form.Label>
          </center>
          <div className="image_upload_receipt">
            <UploadReceipt img={img} setImg={setImg} />
          </div>
          <br></br>
          <Form.Group className="mb-3 input_money">
            <p style={{ color: "red", fontSize: "12px" }}>
              * ใบเสร็จเฉพาะวันที่ 15 ตุลาคม 2567 – 25 พฤศจิกายน 2567
            </p>

            {campaignType === "meet" ? (
              <Form.Label>ยอดเงินของคุณ (ขั้นต่ำ 1,000 บาท) </Form.Label>
            ) : campaignType === "gold" ? (
              <Form.Label> ยอดเงินของคุณ (ขั้นต่ำ 500 บาท) </Form.Label>
            ) : null}

            <InputNumber
              type="tel"
              style={{ width: "100%" }}
              placeholder="ยอดเงินของคุณ"
              value={price}
              min={1}
              max={9999999}
              defaultValue={3}
              onChange={onChange}
            />
          </Form.Group>
          <div className="bath">&nbsp;บาท</div>
          <Form.Label style={{ width: "100%" }}>
            วันที่ซื้อ (ตามใบเสร็จ)
          </Form.Label>
          <input
            type="date"
            className="form-control"
            placeholder="วันเดือนปี"
            style={{ width: "100%" }}
            max={maxDate}
            onChange={(e) => setDate(e.target.value)}
          ></input>
          <br></br>
          <div id="boxproduct">
            <div id="product_box">
              <Space style={{ width: "100%" }} direction="vertical">
                <Radio.Group onChange={handleRadioChange} value={selectedType}>
                  <Space direction="vertical">
                    <Radio value="cat">Cat</Radio>
                    <Radio value="dog">Dog</Radio>
                  </Space>
                </Radio.Group>
                {/* <Select
                  className="form-control"
                  mode="multiple"
                  allowClear
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="เลือกสินค้าที่ซื้อ"
                  onChange={(value) => handleChange(value, selectedType)}
                  value={selectedProducts.map((product) => product.id)}
                  filterOption={(
                    input,
                    option // Custom filter function
                  ) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {ReceiptProduct.map((item) => (
                    <Select.Option
                      key={`${item.id}-${item.name}`}
                      value={item.id}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
                </Select> */}
              </Space>
            </div>
          </div>
          <br></br>
          <br></br>
          <br></br>
          <div className="font_receipt">ซื้อจากที่ไหน</div>
          <Form.Label className="">ช่องทางการซื้อ</Form.Label>

          <Form.Select
            id="ddl"
            style={{ backgroundColor: "#F5F5F5" }}
            aria-label="Default select example"
            onChange={handleTypeChange}
          >
            <option>เลือกช่องทางการซื้อ</option>

            <option value="online">Online</option>
            <option value="onsite">Petshop</option>
            {/* <option value="event">Event</option> */}
          </Form.Select>
          <br></br>

          {type === "online" && (
            <div id="box1">
              <div id="online_box">
                {/* <Form.Select
                  style={{ backgroundColor: "#F5F5F5" }}
                  value={shop_id}
                  onChange={(e) => setShop_id(e.target.value)}
                >
                  <option>
                    เลือกช่องทางการซื้อ
                  </option>
                  {ShopOnlineType.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </Form.Select> */}
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  placeholder="เลือกช่องทางการซื้อ"
                  optionFilterProp="label"
                  onChange={(value) => setShop_id(value)}
                  filterOption={(
                    input,
                    option // Custom filter function
                  ) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {ShopOnlineType?.map((item, index) => (
                    <Select.Option key={`shop${index}`} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          )}
          {type === "onsite" && (
            <div id="box2">
              <div className="dropdown_receipt_offline" id="offline_box">
                <Form.Select
                  style={{ backgroundColor: "#F5F5F5" }}
                  aria-label="Default select example"
                  onChange={handleProvince}
                  value={province}
                >
                  <option>จังหวัด</option>
                  {provinces.map((item) => (
                    <option value={item.id}>{item.name_th}</option>
                  ))}
                </Form.Select>
              </div>
              &nbsp;&nbsp;
              <div className="dropdown_receipt_offline">
                {/* <Form.Select
                  style={{ backgroundColor: "#F5F5F5" }}
                  aria-label="Default select example"
                  value={shop_id}
                  onChange={(e) => setShop_id(e.target.value)}
                >
                  <option>ชื่อร้าน</option>
                  {shopProvinces.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </Form.Select> */}
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  placeholder="ชื่อร้าน"
                  optionFilterProp="label"
                  onChange={(value) => setShop_id(value)}
                  filterOption={(
                    input,
                    option // Custom filter function
                  ) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {shopProvinces?.map((item, index) => (
                    <Select.Option key={`shop${index}`} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          )}
          {type === "event" && (
            <div id="box3">
              <div id="event_box">
                {/* <Form.Select
                  style={{ backgroundColor: "#F5F5F5" }}
                  value={shop_id}
                  onChange={(e) => setShop_id(e.target.value)}
                >
                  <option value="" selected="selected" disabled>
                    เลือกช่องทางการซื้อ
                  </option>
                  {EventShop.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </Form.Select> */}
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  placeholder="เลือกช่องทางการซื้อ"
                  optionFilterProp="label"
                  onChange={(value) => setShop_id(value)}
                  filterOption={(
                    input,
                    option // Custom filter function
                  ) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {EventShop?.map((item, index) => (
                    <Select.Option key={`shop${index}`} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          )}
          <br></br>

          <center>
            <Form.Group controlId="formBasicCheckbox">
              {loading ? (
                <LoadingOutlined className="text-danger" />
              ) : (
                <Button
                  style={{
                    backgroundColor: "#FF0099",
                    borderColor: "#FF0099",
                    width: "90px",
                  }}
                  variant="primary"
                  type="submit"
                  loadings
                >
                  บันทึก{" "}
                </Button>
              )}
            </Form.Group>
          </center>
        </Form>
        <br></br>
        <br></br>
        <div
          className="mb-3 resgisterFoodter rules"
          controlId="formBasicCheckbox"
        >
          <b>
            <h4 style={{ color: "#FF0099" }}>
              <Button
                style={{ color: "#FF0099" }}
                className="icon_3"
                variant="light"
              >
                <b className="font_ButtonUpload"> </b>{" "}
              </Button>
              &nbsp;&nbsp;กติกาและเงื่อนไข
            </h4>
          </b>
          <Row className="displaychecklistDetail_home">
            <p>
              <strong>
                วิธีการเข้าร่วมกิจกรรม "Moochie Pet Food X LingLingKwong
                MakeMoreHappy"
              </strong>
            </p>
            <p>
              <span dir="ltr" lang="TH">
                ลุ้นรับจี้&nbsp;
              </span>
              Moochie&nbsp;
              <span dir="ltr" lang="TH">
                และทอง&nbsp;
              </span>
              1&nbsp;
              <span dir="ltr" lang="TH">
                กรัม&nbsp;
              </span>
              10&nbsp;
              <span dir="ltr" lang="TH">
                รางวัล&nbsp;รวมมูลค่า&nbsp;
              </span>
              50,000&nbsp;
              <span dir="ltr" lang="TH">
                บาท&nbsp;และร่วม&nbsp;
              </span>
              Special Event Moochie Pet Food x
            </p>
            <p>
              LingLingKwong Make More Happy 700&nbsp;
              <span dir="ltr" lang="TH">
                ท่าน
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>1.</strong>
              <span dir="ltr" lang="TH">
                <strong>วิธีการเข้าร่วมกิจกรรม</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong> "</strong>
              </span>
              <strong>MoochiePetFood X LinLingKwong MakeMoreHappy"</strong>
            </p>

            <p>
              1.1&nbsp;
              <span dir="ltr" lang="TH">
                ซื้อสินค้า
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Moochie Pet Food&nbsp;
              <span dir="ltr" lang="TH">
                ชนิดใดก็ได้
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ผ่านช่องทางออนไลน์
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Moochie Pet Food Official&nbsp;
            </p>

            <p>
              - 500
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                บาท
              </span>
              <span dir="ltr" lang="TH">
                /
              </span>
              <span dir="ltr" lang="TH">
                ใบเสร็จ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ได้รับสิทธิ์
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              1
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                สิทธิ์
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ลุ้นจับฉลากรางวัลจี้
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Moochie&nbsp;
              <span dir="ltr" lang="TH">
                และทอง
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              1
              <span dir="ltr" lang="TH">
                กรัม
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              10
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                รางวัล
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                รวมมูลค่า
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              50,000
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                บาท
              </span>
            </p>
            <p>
              - 1,000
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                บาท
              </span>
              <span dir="ltr" lang="TH">
                /
              </span>
              <span dir="ltr" lang="TH">
                ใบเสร็จ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ได้รับสิทธิ์
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              1
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                สิทธิ์
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ลุ้นจับฉลากเข้าร่วมงาน
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Special Event Moochie Pet Food x LingLingKwong Make More Happy
            </p>
            <p>
              - 3,000&nbsp;
              <span dir="ltr" lang="TH">
                บาท
              </span>
              <span dir="ltr" lang="TH">
                /
              </span>
              <span dir="ltr" lang="TH">
                ใบเสร็จ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ได้รับสิทธิ์
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              5&nbsp;
              <span dir="ltr" lang="TH">
                สิทธิ์
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ลุ้นจับฉลากเข้าร่วมงาน
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Special Event Moochie Pet Food x LingLingKwong Make More Happy
            </p>
            <p>&nbsp;</p>
            <p>
              1.2.
              <span dir="ltr" lang="TH">
                ซื้อสินค้า
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Moochie Pet Food&nbsp;
              <span dir="ltr" lang="TH">
                ผ่านร้าน
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Pet Shop&nbsp;
              <span dir="ltr" lang="TH">
                ที่เข้าร่วมรายการทั่วประเทศ
              </span>
              <span dir="ltr" lang="TH">
                {" "}
                (
              </span>
              <span dir="ltr" lang="TH">
                สามารถซื้อผ่านร้านค้า
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Pet Shop&nbsp;
              <span dir="ltr" lang="TH">
                ได้ทั้งช่องทางออนไลน์
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                และหน้าร้าน
              </span>
              <span dir="ltr" lang="TH">
                )
              </span>
            </p>
            <p>
              <span dir="ltr" lang="TH">
                พิเศษ
              </span>
              <span dir="ltr" lang="TH">
                !!&nbsp;
              </span>
              <span dir="ltr" lang="TH">
                รับจำนวนสิทธิ์เพิ่มไปเลย
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              - 500
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                บาท
              </span>
              <span dir="ltr" lang="TH">
                /
              </span>
              <span dir="ltr" lang="TH">
                ใบเสร็จ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ได้รับสิทธิ์
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              2
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                สิทธิ์
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ลุ้นจับฉลากรางวัลจี้
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Moochie&nbsp;
              <span dir="ltr" lang="TH">
                และทอง
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              1
              <span dir="ltr" lang="TH">
                กรัม
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              10
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                รางวัล
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                รวมมูลค่า
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              50,000
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                บาท
              </span>
            </p>
            <p>
              - 1,000&nbsp;
              <span dir="ltr" lang="TH">
                บาท
              </span>
              <span dir="ltr" lang="TH">
                /
              </span>
              <span dir="ltr" lang="TH">
                ใบเสร็จ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ได้รับ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              3&nbsp;
              <span dir="ltr" lang="TH">
                สิทธิ์
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ลุ้นจับฉลากเข้าร่วมงาน
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Special Event Moochie Pet Food x LingLingKwong Make More Happy
            </p>
            <p>
              - 3,000&nbsp;
              <span dir="ltr" lang="TH">
                บาท
              </span>
              <span dir="ltr" lang="TH">
                {" "}
                /&nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ใบเสร็จ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ได้รับ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              10&nbsp;
              <span dir="ltr" lang="TH">
                สิทธิ์
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ลุ้นจับฉลากเข้าร่วมงาน
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Special Event Moochie Pet Food x LingLingKwong Make More Happy
              <br />
              &nbsp;
            </p>
            <p>
              <span dir="ltr" lang="TH">
                โดยสามารถซื้อสินค้า
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Moochie Pet Food&nbsp;
              <span dir="ltr" lang="TH">
                และเข้าร่วมกิจกรรมได้ตั้งแต่วันที่
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              15&nbsp;
              <span dir="ltr" lang="TH">
                ตุลาคม
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              2567 - 25&nbsp;
              <span dir="ltr" lang="TH">
                พฤศจิกายน
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              2567&nbsp;
              <span dir="ltr" lang="TH">
                เวลา
              </span>{" "}
              23.00&nbsp;
              <span dir="ltr" lang="TH">
                น
              </span>
              <span dir="ltr" lang="TH">
                .
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>2.&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>สมัครเข้าร่วมกิจกรรม</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>และอัพโหลดใบเสร็จที่</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>Line Official : @Moochie&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>กิจกรรม</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>MoochiePetFood X LingLingKwong MakeMoreHappy</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>3.&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>สำหรับผู้ที่</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>Upload&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>
                  ใบเสร็จเสร็จสิ้นจะได้รับสิทธิ์ตามจำนวนสิทธิ์ในการซื้อสินค้า
                </strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>/</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>ใบเสร็จ</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>ตามที่แจ้งข้างต้น</strong>
              </span>
            </p>
            <p>
              <span dir="ltr" lang="TH">
                <strong>โดยแบ่งออกเป็น</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>2&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>รูปแบบ</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>คือ</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>1.</strong>
              <span dir="ltr" lang="TH">
                <strong>ใบเสร็จมูลค่า</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>1,000&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>บาท</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>และใบเสร็จมูลค่า</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>3,000&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>บาท</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>
                  จำนวนสิทธิ์ที่ทุกท่านได้รับจะถูกนำทำเป็นฉลากเพื่อจับรางวัล
                </strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>1&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>สิทธิ์</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong> =&nbsp;</strong>
              </span>
              <strong>1&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>ฉลาก</strong>
              </span>
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              <strong>4.&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>
                  ระบบจะแสดงสิทธิ์ที่ผู้เข้าร่วมกิจกรรมได้รับหลังอัพโหลดใบเสร็จภายใน
                </strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>3&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>วันทำการ</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>โดยจะตัดรอบใบเสร็จที่อัพโหลดมา</strong>
              </span>
            </p>
            <p>
              <span dir="ltr" lang="TH">
                <strong>ภายใน</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>18.00&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>น</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>.&nbsp;</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>
                  ของวันเพื่อทำการตรวจสอบความถูกต้องของใบเสร็จเพื่อแสดงสิทธิ์
                </strong>
              </span>
            </p>
            <p>
              <strong>5.&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>คณะกรรมการจะสุ่ม</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>700&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>ผู้โชคดีที่ได้รับสิทธิ์เข้าร่วมงาน</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>โดยจำนวนที่นั่งจะแบ่งเป็น</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>2 Zone&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>คือ</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>1. Zone&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>ที่นั่งสำหรับผู้โชคดีที่ซื้อสินค้า</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>3,000&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>บาท</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>/&nbsp;</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>ใบเสร็จ</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>จำนวน</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>350&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>ที่นั่ง</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>2. Zone&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>ที่นั่งสำหรับผู้โชคดีที่ซื้อสินค้า</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>1,000&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>บาท</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>/&nbsp;</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>ใบเสร็จ</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>350&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>ที่นั่ง</strong>
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>6.&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>ผู้ที่ได้รับสิทธิ์เข้าร่วมงาน</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>ทาง</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>Moochie Pet Food&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>ขอสงวนสิทธิ์ผู้โชคดีแค่</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>1&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>สิทธิ์สำหรับการเข้าร่วมงาน</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong> **</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>สิทธิ์ไม่สามารถโอนให้ท่านอื่นได้</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>**</strong>
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>7.&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>สำหรับผู้โชคดีที่ได้เข้าร่วมงาน</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>
                  ตำแหน่งที่นั่งภายในงานจะถูกสุ่มเลือกพร้อมกันกับการสุ่มผู้โชคดีเข้าร่วมงาน
                </strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>700&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>ท่าน</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>และได้นั่งภายใน</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>Zone&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>ตามใบเสร็จที่ได้รับจับฉลาก</strong>
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>8.&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>สำหรับผู้โชคดี</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>700&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>ท่านที่ได้เข้าร่วมงาน</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>
                  ผู้ที่ได้รับสิทธิ์เยอะที่สุดจากการร่วมกิจกรรมจะได้รับสิทธิพิเศษในการเข้าร่วมงาน
                </strong>
              </span>
            </p>
            <p>
              <span dir="ltr" lang="TH">
                <strong>ใน</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>Zone VVIP&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>จำนวน</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>32&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>ที่นั่ง</strong>
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>9.&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>สำหรับ</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>Benefit&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>เพิ่มเติมสำหรับการเข้าร่วมงาน</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>700&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>ท่าน</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>ทาง</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>Moochie&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>จะสุ่มเลือกผู้โชคดีต่อเพื่อมอบ</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>Benefit</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>10.&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>คณะกรรมการจะสุ่มเลือกผู้โชคดีที่ได้รับ</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>Benefit&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>เพิ่มเติม</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>จาก</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>700&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>ท่านที่ได้เข้าร่วมงาน</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>Special Event</strong>
            </p>
            <p>&nbsp;</p>
            <p>
              "MoochiePetFood X LinLingKwong MakeMoreHappy&rdquo;&nbsp;
              <span dir="ltr" lang="TH">
                โดยจะแบ่งผู้โชคดีที่ได้
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Benefit&nbsp;
              <span dir="ltr" lang="TH">
                แตกต่างกันตามมูลค่าใบเสร็จ
              </span>{" "}
              (1,000&nbsp;
              <span dir="ltr" lang="TH">
                บาท
              </span>
              <span dir="ltr" lang="TH">
                /&nbsp;
              </span>
              3,000&nbsp;
              <span dir="ltr" lang="TH">
                บาท
              </span>
              <span dir="ltr" lang="TH">
                )&nbsp;
              </span>
              <span dir="ltr" lang="TH">
                เพื่อมอบ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Benefit&nbsp;
              <span dir="ltr" lang="TH">
                ให้ผู้โชคดีตามใบเสร็จการซื้อสินค้า
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                การตัดสินของคณะกรรมการถือเป็นที่สิ้นสุดและเงื่อนไขเป็นไปตามที่บริษัทกำหนด
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>11.&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>สิทธิ์ที่ได้รับจากการซื้อสินค้าต่อใบเสร็จ</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>500&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>บาท</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong> /</strong>
              </span>
              <strong>1,000&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>บาท</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong> /&nbsp;</strong>
              </span>
              <strong>3,000&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>บาท</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>จะไม่สามารถนำมารวมกันได้</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>
                  สิทธิ์ที่ได้แต่ละใบเสร็จจะถูกนำไปจับฉลากลุ้นรับรางวัลตามรางวัลของแต่ละใบเสร็จเท่านั้น
                </strong>
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>12.&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>ใบเสร็จจากการซื้อสินค้า</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>Moochie&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>สามารถอัพโหลดเพื่อเข้าร่วมกิจกรรมได้</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>1&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>ครั้งต่อกิจกรรมเท่านั้น</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>เมื่ออัพโหลดเข้าร่วม</strong>
              </span>
            </p>
            <p>
              <span dir="ltr" lang="TH">
                <strong>
                  กิจกรรมใดแล้วจะไม่สามารถนำไปใช้ซ้ำในการเข้าร่วมกิจกรรมอื่นๆของทาง
                </strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>Moochie&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>ได้อีก</strong>
              </span>
            </p>
            <p>
              <span dir="ltr" lang="TH">
                <strong>ช่องทางการซื้อสินค้า</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>Moochie Pet Food</strong>
            </p>
            <p>
              1.
              <span dir="ltr" lang="TH">
                ซื้อสินค้าทางออนไลน์ของ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Moochie Pet Food Official
            </p>
            <p>Website : shop.moochiepetfood.com</p>
            <p>Shopee : https://shopee.co.th/moochiepetfood</p>
            <p>Lazada :https://www.lazada.co.th/shop/moochiepetfood</p>
            <p>Line Official : @Moochie</p>
            <p>
              2.&nbsp;
              <span dir="ltr" lang="TH">
                ซื้อสินค้าผ่าน
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ร้าน
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Pet Shop&nbsp;
              <span dir="ltr" lang="TH">
                ทั่วประเทศ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ที่เข้าร่วมรายการทั่วประเทศ
              </span>
              <span dir="ltr" lang="TH">
                {" "}
                (
              </span>
              <span dir="ltr" lang="TH">
                สามารถซื้อผ่านร้านค้า
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Pet Shop&nbsp;
              <span dir="ltr" lang="TH">
                ได้ทั้งช่องทาง
              </span>
            </p>
            <p>
              <span dir="ltr" lang="TH">
                ออนไลน์
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                และหน้าร้าน
              </span>
              <span dir="ltr" lang="TH">
                )
              </span>
              <span dir="ltr" lang="TH">
                ที่จำหน่ายสินค้า
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Moochie Pet Food&nbsp;
              <span dir="ltr" lang="TH">
                ได้ที่
              </span>
              https://shop.moochiepetfood.com
            </p>
            <p>
              <span dir="ltr" lang="TH">
                สำหรับท่านใดที่ซื้อสินค้า
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Moochie Pet Food&nbsp;
              <span dir="ltr" lang="TH">
                สามารถนำมาร่วมทำบุญบริจาคอาหารให้น้องๆ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                กับ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Moochie&nbsp;
              <br />
              <span dir="ltr" lang="TH">
                และหลิงหลิงได้ที่มูลนิธิบ้านสงเคราะห์สัตว์พิการ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                โดยจะนำผลิตภัณฑ์
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Moochie&nbsp;
              <span dir="ltr" lang="TH">
                ที่ทุกท่านซื้อไปบริจาคให้กับ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                มูลนิธิบ้านสงเคราะห์สัตว์พิการ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ในวันที่
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              23&nbsp;
              <span dir="ltr" lang="TH">
                ธันวาคม
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              2567
              <br />
              &nbsp;
            </p>
            <p>
              <span dir="ltr" lang="TH">
                <strong>วิธีการเข้าร่วมบริจาค</strong>
              </span>
            </p>
            <p>
              1.
              <span dir="ltr" lang="TH">
                สำหรับผู้ที่ซื้อสินค้า
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Moochie Pet Food&nbsp;
              <span dir="ltr" lang="TH">
                ผ่านช่องทางออนไลน์
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ข้อมูลการจัดส่งสินค้าให้กรอกที่อยู่บริษัท
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ไทย
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ออซัม
              </span>
            </p>
            <p>
              <span dir="ltr" lang="TH">
                จำกัด
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              108/126&nbsp;
              <span dir="ltr" lang="TH">
                หมู่
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              5&nbsp;
              <span dir="ltr" lang="TH">
                ซอยแจ้งวัฒนะ
              </span>
              20&nbsp;
              <span dir="ltr" lang="TH">
                ถนนแจ้งวัฒนะ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ต
              </span>
              <span dir="ltr" lang="TH">
                .
              </span>
              <span dir="ltr" lang="TH">
                ปากเกร็ด
              </span>
              ,&nbsp;
              <span dir="ltr" lang="TH">
                อ
              </span>
              <span dir="ltr" lang="TH">
                .
              </span>
              <span dir="ltr" lang="TH">
                ปากเกร็ด
              </span>
              ,&nbsp;
              <span dir="ltr" lang="TH">
                นนทบุรี
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ประเทศไทย
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              11120
            </p>
            <p>
              2.&nbsp;
              <span dir="ltr" lang="TH">
                สำหรับผู้ที่ซื้อผ่านหน้าร้าน
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Pet Shop&nbsp;
              <span dir="ltr" lang="TH">
                ให้แจ้งทางหน้าร้านมีความประสงค์ในการเข้าร่วมบริจาคอาหารสัตว์
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ทางร้านจะนำ
              </span>
            </p>
            <p>
              <span dir="ltr" lang="TH">
                สินค้า
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Moochie&nbsp;
              <span dir="ltr" lang="TH">
                ที่ท่านซื้อส่งกลับมาที่บริษัทฯ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                เพื่อดำเนินการบริจาคให้ทุกท่านต่อไป
              </span>
            </p>
            <p>
              <span dir="ltr" lang="TH">
                สามารถซื้อสินค้า
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Moochie Pet Food&nbsp;
              <span dir="ltr" lang="TH">
                และเข้าร่วมกิจกรรมได้ตั้งแต่วันที่
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              15&nbsp;
              <span dir="ltr" lang="TH">
                ตุลาคม
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              2567 - 25&nbsp;
              <span dir="ltr" lang="TH">
                พฤศจิกายน
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              2567&nbsp;
              <span dir="ltr" lang="TH">
                เวลา
              </span>{" "}
              23.00&nbsp;
              <span dir="ltr" lang="TH">
                น
              </span>
              <span dir="ltr" lang="TH">
                .
              </span>
            </p>
            <p>
              <span dir="ltr" lang="TH">
                ทางแบรนด์จะจับฉลากผู้โชคดีที่ได้รับสิทธิ์เข้าร่วมงาน
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              &ldquo;Special Event &ldquo;MoochiePetFood X LingLingKwong
            </p>
            <p>MakeMoreHappy&rdquo;</p>
            <p>&nbsp;</p>
            <p>
              <span dir="ltr" lang="TH">
                ทั้ง
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              700&nbsp;
              <span dir="ltr" lang="TH">
                ท่าน
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                และจับฉลากผู้โชคดีที่ได้รับจี้
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Moochie&nbsp;
              <span dir="ltr" lang="TH">
                และทอง
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              1&nbsp;
              <span dir="ltr" lang="TH">
                กรัม
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              10&nbsp;
              <span dir="ltr" lang="TH">
                รางวัล
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                รวมมูลค่า
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              50,000&nbsp;
              <span dir="ltr" lang="TH">
                บาท
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ในกิจกรรม
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              &ldquo;MoochiePetFood X LingLingKwong MakeMoreHappy&rdquo;&nbsp;
              <span dir="ltr" lang="TH">
                ทั้ง
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              10
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                รางวัล
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ในวันที่
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              2
              <span dir="ltr" lang="TH">
                ธันวาคม
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              2567
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                เวลา
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              14.00
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                น
              </span>
              <span dir="ltr" lang="TH">
                .&nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ณ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                บริษัท
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ไทย
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ออซัม
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                จำกัด
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              108/126&nbsp;
              <span dir="ltr" lang="TH">
                หมู่
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              5&nbsp;
              <span dir="ltr" lang="TH">
                ซอยแจ้งวัฒนะ
              </span>
              20&nbsp;
              <span dir="ltr" lang="TH">
                ถนนแจ้งวัฒนะ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ต
              </span>
              <span dir="ltr" lang="TH">
                .
              </span>
              <span dir="ltr" lang="TH">
                ปากเกร็ด
              </span>
              ,&nbsp;
              <span dir="ltr" lang="TH">
                อ
              </span>
              <span dir="ltr" lang="TH">
                .
              </span>
              <span dir="ltr" lang="TH">
                ปากเกร็ด
              </span>
              ,&nbsp;
              <span dir="ltr" lang="TH">
                นนทบุรี
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ประเทศไทย
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              11120
            </p>
            <p>&nbsp;</p>
            <p>
              <span dir="ltr" lang="TH">
                ประกาศรายชื่อผู้โชคดี
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              700&nbsp;
              <span dir="ltr" lang="TH">
                ท่านที่ได้เข้าร่วมงาน
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Special Event &ldquo;MoochiePetFood X LingLingKwong
              MakeMoreHappy&rdquo;
            </p>
            <p>&nbsp;</p>
            <p>
              <span dir="ltr" lang="TH">
                และ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ประกาศผู้โชคดีได้รับจี้
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Moochie&nbsp;
              <span dir="ltr" lang="TH">
                และทอง
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              1&nbsp;
              <span dir="ltr" lang="TH">
                กรัม
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              10&nbsp;
              <span dir="ltr" lang="TH">
                รางวัล
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                วันที่
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              6&nbsp;
              <span dir="ltr" lang="TH">
                ธันวาคม
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              2567&nbsp;
              <span dir="ltr" lang="TH">
                ที่
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Facebook Fanpage. : Moochie Pet Food&nbsp;
              <span dir="ltr" lang="TH">
                เวลา
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              16.00&nbsp;
              <span dir="ltr" lang="TH">
                น
              </span>
              <span dir="ltr" lang="TH">
                .&nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ยืนยันรับสิทธิ์ผ่าน
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Facebook Inbox Moochie Pet Food
            </p>
            <p>
              <span dir="ltr" lang="TH">
                ภายวันที่
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              12&nbsp;
              <span dir="ltr" lang="TH">
                ธันวาคม
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              2567&nbsp;
              <span dir="ltr" lang="TH">
                เวลา
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              23.00&nbsp;
              <span dir="ltr" lang="TH">
                น
              </span>
              <span dir="ltr" lang="TH">
                .&nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ทางแบรนด์ขอสงวนสิทธิ์มอบรางวัลจี้
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Moochie&nbsp;
              <span dir="ltr" lang="TH">
                พร้อมทองคำ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              1&nbsp;
              <span dir="ltr" lang="TH">
                กรัม
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                และบัตรที่นั่งให้กับผู้ได้รับสิทธิ์เข้าร่วมงาน
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Special Event MoochiePetFood X LingLingKwong MakeMoreHappy&nbsp;
              <span dir="ltr" lang="TH">
                ให้กับผู้ที่มายืนยันสิทธิ์เท่านั้น
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span dir="ltr" lang="TH">
                ประกาศที่นั่งสำหรับผู้โชคดี
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              700&nbsp;
              <span dir="ltr" lang="TH">
                ท่านที่ได้เข้าร่วมงาน
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                และสิทธิ์พิเศษที่ผู้เข้าร่วมงานได้รับในวันที่
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              15&nbsp;
              <span dir="ltr" lang="TH">
                ธันวาคม
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              2567&nbsp;
              <span dir="ltr" lang="TH">
                เวลา
              </span>
            </p>
            <p>
              17.00&nbsp;
              <span dir="ltr" lang="TH">
                น
              </span>
              <span dir="ltr" lang="TH">
                .&nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ที่
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Facebook Fanpge : MoochiePetFood
            </p>
            <p>
              Benefit&nbsp;
              <span dir="ltr" lang="TH">
                จากการร่วมกิจกรรม
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Special Event MoochiePetFood X LingLingKwong MakeMoreHappy
            </p>
            <p>
              <span dir="ltr" lang="TH">
                สำหรับผู้โชคดีที่ได้เข้าร่วมงานทุกท่าน
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                รับ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Exclusive Photo Card 1&nbsp;
              <span dir="ltr" lang="TH">
                ใบ
              </span>
              <span dir="ltr" lang="TH">
                {" "}
                +&nbsp;
              </span>
              Surprise Video&nbsp;
              <span dir="ltr" lang="TH">
                หลิงหลิง
              </span>
              MakeMoreHappy
            </p>
            <p>&nbsp;</p>
            <p>
              <span dir="ltr" lang="TH">
                <strong>สิทธิ์ผู้โชคดี</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>Moochie Pet Food&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>เฉพาะผู้ที่ซื้อสินค้า</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>1,000&nbsp;</strong>
              <span dir="ltr" lang="TH">
                <strong>บาท</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong> /</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>ใบเสร็จ</strong>
              </span>
            </p>
            <p>
              1. 1&nbsp;
              <span dir="ltr" lang="TH">
                สิทธิ์
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ลุ้นรับ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Photo Card&nbsp;
              <span dir="ltr" lang="TH">
                พร้อมลายเซ็นต์หลิงหลิง
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              10&nbsp;
              <span dir="ltr" lang="TH">
                ภาพ
              </span>
            </p>
            <p>
              2. 1&nbsp;
              <span dir="ltr" lang="TH">
                สิทธิ์ได้ลุ้นเล่นกิจกรรมบนเวทีกับหลิงหลิง
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              2&nbsp;
              <span dir="ltr" lang="TH">
                สิทธิ์
              </span>
              <br />
              &nbsp;
            </p>
            <p>&nbsp;</p>
            <p>
              <span dir="ltr" lang="TH">
                <strong>สิทธิ์ผู้โชคดี</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>Moochie (</strong>
              <span dir="ltr" lang="TH">
                <strong>เฉพาะผู้ที่ซื้อสินค้า</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>&nbsp;</strong>
              </span>
              <strong>3,000.-/</strong>
              <span dir="ltr" lang="TH">
                <strong>ใบเสร็จ</strong>
              </span>
              <span dir="ltr" lang="TH">
                <strong>)</strong>
              </span>
            </p>
            <p>
              1. 1&nbsp;
              <span dir="ltr" lang="TH">
                สิทธิ์
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ลุ้นรับ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Photo Card&nbsp;
              <span dir="ltr" lang="TH">
                พร้อมลายเซ็นต์หลิงหลิง
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              20&nbsp;
              <span dir="ltr" lang="TH">
                สิทธิ์
              </span>
            </p>
            <p>
              2. 1&nbsp;
              <span dir="ltr" lang="TH">
                สิทธิ์ลุ้น
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Photo Shot 1:1&nbsp;
              <span dir="ltr" lang="TH">
                ทั้งหมด
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              10&nbsp;
              <span dir="ltr" lang="TH">
                สิทธิ์
              </span>
            </p>
            <p>
              3. 1&nbsp;
              <span dir="ltr" lang="TH">
                สิทธิ์
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ได้ลุ้นเล่นกิจกรรมบนเวทีกับหลิงหลิง
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              3&nbsp;
              <span dir="ltr" lang="TH">
                สิทธิ์
              </span>
            </p>
            <p>
              4. 1&nbsp;
              <span dir="ltr" lang="TH">
                สิทธิ์ได้ลุ้นที่นั่ง
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              VVIP 32&nbsp;
              <span dir="ltr" lang="TH">
                ที่นั่ง
              </span>
            </p>
            <p>
              5. 1&nbsp;
              <span dir="ltr" lang="TH">
                สิทธิ์ได้ลุ้น
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              LingLing Special Gift - Daily More Happy 5&nbsp;
              <span dir="ltr" lang="TH">
                สิทธิ์
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <span dir="ltr" lang="TH">
                สำหรับการ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                ถูกสุ่มเลือก
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Random Benefits{" "}
              <span dir="ltr" lang="TH">
                ที่ผู้ที่ได้เข้าร่วมงานได้รับนั้น
              </span>
            </p>
            <p>
              <span dir="ltr" lang="TH">
                คณะกรรมการจะสุ่มเลือกผู้โชคดีที่ได้รับ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Benefit&nbsp;
              <span dir="ltr" lang="TH">
                เพิ่มเติม
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              <span dir="ltr" lang="TH">
                จาก
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              700&nbsp;
              <span dir="ltr" lang="TH">
                ท่านที่ได้เข้าร่วมงาน
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Special Event "MoochiePetFood X LinLingKwong
              MakeMoreHappy&rdquo;&nbsp;
              <span dir="ltr" lang="TH">
                โดยจะแบ่งผู้โชคดีที่ได้
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Benefit&nbsp;
              <span dir="ltr" lang="TH">
                แตกต่างกันตามมูลค่าใบเสร็จ
              </span>
              <span dir="ltr" lang="TH">
                {" "}
                (
              </span>
              1,000&nbsp;
              <span dir="ltr" lang="TH">
                บาท
              </span>
              <span dir="ltr" lang="TH">
                /&nbsp;
              </span>
              3,000&nbsp;
              <span dir="ltr" lang="TH">
                บาท
              </span>
              <span dir="ltr" lang="TH">
                )&nbsp;
              </span>
              <span dir="ltr" lang="TH">
                เพื่อมอบ
              </span>
              <span dir="ltr" lang="TH">
                &nbsp;
              </span>
              Benefit&nbsp;
              <span dir="ltr" lang="TH">
                ให้ผู้โชคดีตามใบเสร็จการซื้อสินค้า
              </span>
            </p>
            <p>
              <span dir="ltr" lang="TH">
                การตัดสินของคณะกรรมการถือเป็นที่สิ้นสุดและเงื่อนไขเป็นไปตามที่บริษัทกำหนด
              </span>
            </p>
          </Row>
          {/* <Row className="align-items-center displaychecklistDetail_home">
            <Col>
              <table>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}></td>
                  <td>
                  วิธีการเข้าร่วมกิจกรรม "Moochie Pet Food X LingLingKwong MakeMoreHappy
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}></td>
                  <td>
                  ลุ้นรับจี้ Moochie และทอง 1 กรัม 10 รางวัล รวมมูลค่า 50,000 บาท และร่วม Special Event Moochie Pet Food x LingLingKwong Make More Happy 700 ท่าน

                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>1. </td>
                  <td>
                  วิธีการเข้าร่วมกิจกรรม "MoochiePetFood X LinLingKwong MakeMoreHappy"
                    <tr>
                    1.1 &nbsp;  ซื้อสินค้า Moochie Pet Food ชนิดใดก็ได้ ผ่านช่องทางออนไลน์ Moochie Pet Food Official 
                    </tr>
                    <tr>
                      &nbsp;&nbsp;&nbsp;- 500 บาท/ใบเสร็จ ได้รับสิทธิ์ 1 สิทธิ์ ลุ้นจับฉลากรางวัลจี้ Moochie และทอง 1 กรัม 10 รางวัล รวมมูลค่า 50,000 บาท
                    </tr>
                    <tr>
                      &nbsp;&nbsp;&nbsp;- 1,000 บาท/ใบเสร็จ ได้รับสิทธิ์ 1 สิทธิ์ ลุ้นจับฉลากเข้าร่วมงาน Special Event Moochie Pet Food x LingLingKwong Make More Happy
                    </tr>
                    <tr>
                      &nbsp;&nbsp;&nbsp;- 3,000 บาท/ใบเสร็จ ได้รับสิทธิ์ 5 สิทธิ์ ลุ้นจับฉลากเข้าร่วมงาน Special Event Moochie Pet Food x LingLingKwong Make More Happy
                    </tr>
                    <tr>
                    1.2 &nbsp;  ซื้อสินค้า Moochie Pet Food ผ่านร้าน Pet Shop ที่เข้าร่วมรายการทั่วประเทศ (สามารถซื้อผ่านร้านค้า Pet Shop ได้ทั้งช่องทางออนไลน์ และหน้าร้าน) พิเศษ!! รับจำนวนสิทธิ์เพิ่มไปเลย
 
                    </tr>
                    <tr>
                      &nbsp;&nbsp;&nbsp;- 500 บาท/ใบเสร็จ ได้รับสิทธิ์ 1 สิทธิ์ ลุ้นจับฉลากรางวัลจี้ Moochie และทอง 1 กรัม 10 รางวัล รวมมูลค่า 50,000 บาท
                    </tr>
                    <tr>
                      &nbsp;&nbsp;&nbsp;- 1,000 บาท/ใบเสร็จ ได้รับสิทธิ์ 1 สิทธิ์ ลุ้นจับฉลากเข้าร่วมงาน Special Event Moochie Pet Food x LingLingKwong Make More Happy
                    </tr>
                    <tr>
                      &nbsp;&nbsp;&nbsp;- 3,000 บาท/ใบเสร็จ ได้รับสิทธิ์ 5 สิทธิ์ ลุ้นจับฉลากเข้าร่วมงาน Special Event Moochie Pet Food x LingLingKwong Make More Happy
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>1. </td>
                  <td>การสะสมยอดซื้อเพื่อร่วมกิจกรรม Top spender</td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>2. </td>
                  <td>การสะสมยอดซื้อเพื่อร่วมกิจกรรม Top spender</td>
                </tr>

                <tr>
                  <td style={{ verticalAlign: "text-top" }}>3. </td>
                  <td>
                    ของรางวัลแบ่งเป็น 3 ระดับ
                    ตามจำนวนยอดซื้อสินค้าจากทุกช่องทางที่สะสมตามลำดับ (Top spender)
                    <tr>
                      ระดับที่ 1 : เมื่อมียอดซื้อสินค้าสะสมจากทุกช่องทางสูงที่สุด
                    </tr>
                    <tr>
                      &nbsp;&nbsp;&nbsp;- ตั๋วเครื่องบินไป - กลับ 6 วัน5 คืน Bangkok -
                      Hokkaido จำนวน 2 ที่นั่ง มูลค่ากว่า 100,000 บาท จำนวน 1 รางวัล
                    </tr>
                    <tr>
                      ระดับที่ 2: เมื่อมียอดซื้อสินค้าสะสมจากทุกช่องทางเป็นลำดับที่ 2
                    </tr>
                    <tr>
                      &nbsp;&nbsp;&nbsp;- รางวัล ทองคำหนัก 50 สตางค์ มูลค่ากว่า 18,000
                      บาท รางวัลละ 1 เส้น จำนวน 1 รางวัล
                    </tr>
                    <tr>
                      ระดับที่ 3: เมื่อมียอดซื้อสินค้าสะสมจากทุกช่องทางเป็นลำดับที่ 3
                    </tr>
                    <tr>
                      &nbsp;&nbsp;&nbsp;- รางวัล Air pod pro มูลค่ากว่า 9,000 บาท
                      รางวัลละ 1 ชิ้น จำนวน 1 รางวัล ระดับที่ 4 - 10:
                      เมื่อมียอดซื้อสินค้าสะสมจากทุกช่องทางเป็นลำดับที่ 4 - 10
                      ตามลำดับ
                    </tr>
                    <tr>
                      ระดับที่ 4 - 10:
                      เมื่อมียอดซื้อสินค้าสะสมจากทุกช่องทางเป็นลำดับที่ 4 - 10
                      ตามลำดับ
                    </tr>
                    <tr>
                      &nbsp;&nbsp;&nbsp;- รางวัล Gift set Moochie มูลค่า 3,000 บาท
                      รางวัลละ 1 เซ็ท จำนวน 7 รางวัล
                    </tr>
                  </td>
                </tr>

                <tr>
                  <td style={{ verticalAlign: "text-top" }}>4. </td>
                  <td>ของพรีเมียม Moochie สามารถใช้คะแนนสะสมจากยอดซื้อ แลกรับได้</td>
                </tr>

                <tr>
                  <td style={{ verticalAlign: "text-top" }}>5. </td>
                  <td>
                    การสะสมยอดซื้อตามกำหนดเพื่อเป็นสมาชิก MOOCHIE MEMBER
                    มีสิทธิ์รับสิทธิพิเศษตามระดับสมาชิก ดังนี้
                    <tr style={{ marginLeft: "5%" }}>
                      <td style={{ verticalAlign: "text-top" }}> - &nbsp;</td>
                      <td>Moochie Member: เมื่อสมัครเข้าร่วมกิจกรรม รับทันที</td>
                    </tr>
                    <tr style={{ marginLeft: "5%" }}>
                      <td style={{ verticalAlign: "text-top" }}> - &nbsp;</td>
                      <td>ยอดสั่งซื้อไม่มีขั้นต่ำ</td>
                    </tr>
                    <tr style={{ marginLeft: "5%" }}>
                      <td style={{ verticalAlign: "text-top" }}> - &nbsp;</td>
                      <td>Free Pet ID (รับสิทธิพิเศษแล้วแต่กิจกรรมของทางแบรนด์)</td>
                    </tr>
                    <br></br>
                    <tr>
                      <td></td>
                      <td>Moochie Silver Member :</td>
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>
                        มียอดสั่งซื้อสะสมครบ 5,000 บาท ขึ้นไป
                        และรักษายอดตลอดทั้งเดือนไม่ต่ำกว่า 700 บาท
                      </td>
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>Free Pet ID (รับสิทธิพิเศษแล้วแต่กิจกรรมของทางแบรนด์)</td>
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>
                        รับโค้ดส่งสินค้าฟรี 2 ครั้ง/เดือน สำหรับสั่งสินค้าออนไลน์
                      </td>{" "}
                    </tr>
                    <br></br>
                    <tr>
                      <td></td>
                      <td>Moochie Gold Member :</td>
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>
                        มียอดสั่งซื้อสะสมครบ 15,000 บาท ขึ้นไป
                        และรักษายอดตลอดทั้งเดือนไม่ต่ำกว่า 1,500 บาท
                      </td>
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>Free Pet ID (รับสิทธิพิเศษแล้วแต่กิจกรรมของทางแบรนด์)</td>
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>
                        รับโค้ดส่งสินค้าฟรี 5 ครั้ง/เดือน สำหรับสั่งสินค้าออนไลน์
                      </td>{" "}
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>
                        รับส่วนลด 5% (สามารถใช้กับร้านออนไลน์ของทางแบรนด์เท่านั้น)
                      </td>{" "}
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>
                        รับ Gift set ของ premium รุ่น Limited edition จาก Moochie
                        ทันที 1ครั้ง
                      </td>{" "}
                    </tr>
                    <br></br>
                    <tr>
                      <td></td>
                      <td>Moochie Platinum Member :</td>
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>
                        มียอดสั่งซื้อสะสมครบ 30,000 บาท ขึ้นไป
                        และรักษายอดตลอดทั้งเดือนไม่ต่ำกว่า 3,500 บาท
                      </td>
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>Free Pet ID (รับสิทธิพิเศษแล้วแต่กิจกรรมของทางแบรนด์)</td>
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>
                        รับโค้ดส่งสินค้าฟรี 7 ครั้ง/เดือน สำหรับสั่งสินค้าออนไลน์
                      </td>{" "}
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>
                        รับส่วนลด 10% (สามารถใช้กับร้านออนไลน์ของทางแบรนด์เท่านั้น)
                      </td>{" "}
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>
                        ได้รับสิทธิ์ตรวจสุขภาพสัตว์เลี้ยงฟรี ปีละ 1 ครั้ง (Hato
                        wellness)
                      </td>{" "}
                    </tr>
                    <tr>
                      <td>- </td>
                      <td>
                        รับ Gift set ของ premium รุ่น Limited edition จาก Moochie
                        ทันที 1ครั้ง
                      </td>{" "}
                    </tr>
                    <br></br>
                    <tr>
                      <td></td>
                      <td>
                        หมายเหตุ: กรณีไม่มียอดซื้อขั้นต่ำตามกำหนดในแต่ละเดือน
                        จะไม่ได้รับสิทธิประโยชน์ในเดือนนั้น ๆแต่จะไม่ถูกลดระดับสิทธิ์
                        member{" "}
                      </td>{" "}
                    </tr>
                  </td>
                </tr>

                <tr>
                  <td style={{ verticalAlign: "text-top" }}>6. </td>
                  <td>
                    ทุก ๆ ยอดสินค้า 100 บาท = 1 คะแนน
                    (สามารถซื้อสินค้าเพื่อร่วมกิจกรรมสะสมคะแนนได้จากทุกช่องทาง)
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>7. </td>
                  <td>
                    ทุกคะแนนที่สะสมสามารถใช้แลกของพรีเมียม Moochie
                    ได้ตามที่แบรนด์กำหนด
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>8. </td>
                  <td>
                    ยอดซื้อสินค้าที่สะสมได้สูงสุดตามลำดับตั้งแต่ ลำดับที่ 1 – 10
                    มีสิทธิ์ได้รับรางวัลตามที่แบรนด์กำหนด
                  </td>
                </tr>
              </table>
              <br></br>
              วิธีการร่วมกิจกรรม
              <table>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>1. </td>
                  <td>
                    สะสมยอดซื้อจากใบเสร็จทุกช่องทาง (Online shop & Pet shop หรือ งาน
                    Event ของ Moochie ตลอดแคมเปญ)
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>2. </td>
                  <td>
                    อัปโหลดภาพใบเสร็จผ่านระบบ และรอ Admin approve ยอดใบเสร็จ
                    (ตัดยอดใบเสร็จทุก 18.00 น. ของทุกวัน)
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>3. </td>
                  <td>ทุกๆ ยอดซื้อ 100 บาท รับ 1 คะแนน</td>{" "}
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>4. </td>
                  <td>คะแนนสะสมจากยอดซื้อใบเสร็จ จะแสดงผลในวันถัดไป</td>{" "}
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>5. </td>
                  <td>
                    สามารถร่วมสนุกได้โดย ลงทะเบียนผ่าน LINE OFFICIAL MOOCHIE:
                    https://lin.ee/eszT5MR เท่านั้น
                  </td>{" "}
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>6. </td>
                  <td>
                    ผู้ร่วมกิจกรรมต้องกรอกข้อมูลตามที่ระบบกำหนดให้ครบถ้วน
                    เพื่อรับสิทธิ์ตามที่แบรนด์กำหนด
                  </td>{" "}
                </tr>
              </table>
              <br></br>
              กำหนดการประกาศรางวัล
              <p style={{ marginLeft: "5%" }}>
                กำหนดประกาศผู้ได้รับรางวัลตามยอดซื้อสะสม (Top spender) 10
                ลำดับวันศุกร์ ที่ 6 ธันวาคม 2567 เวลา 15.00 น. (วัน
                และเวลาอาจมีการเปลี่ยนแปลงตามที่บริษัทกำหนด) ทางเว็บไซต์กิจกรรม
                โดยสามารถเข้าผ่าน LINE OFFICIAL MOOCHIE: https://lin.ee/eszT5MR
              </p>
              <br></br>
              วิธีการประกาศรางวัล
              <p style={{ marginLeft: "5%" }}>
                บริษัทฯ จะประกาศผู้ได้รับรางวัลตามยอดซื้อสะสม (Top spender) 10 ลำดับ
                วันศุกร์ ที่ 6 ธันวาคม 2567 เวลา 15.00 น. (วัน
                และเวลาอาจมีการเปลี่ยนแปลงตามที่บริษัทกำหนด) ทางเว็บไซต์กิจกรรม
                โดยสามารถเข้าผ่าน LINE OFFICIAL MOOCHIE: https://lin.ee/eszT5MR
              </p>
              <br></br>
              วิธีการร่วมกิจกรรม
              <table>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>1. </td>
                  <td>
                    กิจกรรมเริ่มตั้งแต่วันที่ 4 มีนาคม 2567 ถึง 30 พฤศจิกายน 2567
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>2. </td>
                  <td>
                    สิ่งของรางวัลไม่สามารถนำไปแลกเป็นเงินสดหรือสิ่งของอื่นใดได้
                    ไม่สามารถโอนให้ผู้อื่นได้ และไม่มีการจ่ายเงินเป็นส่วนประกอบ
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>3. </td>
                  <td>
                    การจับรางวัลจะประกาศผู้ได้รับรางวัลตามยอดซื้อสะสม (Top spender) 10
                    ลำดับ{" "}
                  </td>{" "}
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>4. </td>
                  <td>
                    ผู้โชคดีที่ได้รับรางวัลทั้งที่มีภูมิลำเนาในกรุงเทพมหานคร
                    และต่างจังหวัดต้องนำหลักฐานบัตรประจำตัวประชาชน พร้อมสำเนา 1 ชุด
                    มาเป็นหลักฐานในการขอรับรางวัลด้วยตนเองที่บริษัท ไทยออซัม จำกัด
                    (มหาชน) สำนักงานแห่งใหญ่ตั้งอยู่เลขที่ 108/126 ซอยแจ้งวัฒนะ 20
                    ถนนแจ้งวัฒนะ ตำบลปากเกร็ด อำเภอปากเกร็ด จังหวัดนนทบุรี
                    ในวันและเวลาทำการภายในระยะ 7 วันนับแต่วันที่ประกาศรายชื่อผู้โชคดี
                    หากเลยกำหนดถือว่าสละสิทธิ์ และบริษัทฯ
                    จะมอบของรางวัลดังกล่าวให้กับองค์กรสาธารณกุศลต่อไป
                  </td>{" "}
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>5. </td>
                  <td>
                    บริษัทฯ มีสิทธิ์คัดเลือกรายชื่อผู้ได้รับรางวัลสำรองไว้
                    สำหรับกรณีที่ผู้มีสิทธิ์ได้รับรางวัล และ/หรือ
                    ผู้ได้รับรางวัลต้องถูกตัดสิทธิ์จากการรับรางวัลเนื่องจากมีคุณสมบัติไม่ครบถ้วนหรือไม่ปฏิบัติตามกติกาและเงื่อนไขของการจับรางวัลที่กำหนดไว้
                  </td>{" "}
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>6. </td>
                  <td>
                    ผู้โชคดีที่ได้รับรางวัลมูลค่า 1,000 บาทขึ้นไป จะต้องหักภาษี ณ
                    ที่จ่าย ของมูลค่ารางวัล ตามคำสั่ง กรมสรรพากร ที่ ท.ป. 4/2528
                    ประกอบคำสั่งกรมสรรพากรที่ ท.ป. 104/2544 และ
                    ค่าธรรมเนียมและค่าภาษีอื่น ๆ
                    ที่เกิดขึ้นนอกเหนือจากมูลค่าสิ่งของรางวัลที่ได้รับ
                    ผู้โชคดีจะต้องเป็นผู้รับผิดชอบค่าใช้จ่ายเอง
                  </td>{" "}
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>7. </td>
                  <td>
                    ค่าธรรมเนียม ค่าภาษีอื่น ค่าใช้จ่ายอื่น และ ส่วนเกินมูลค่าสินค้า
                    ที่เกิดขึ้นนอกเหนือจากมูลค่าสิ่งของรางวัลที่ได้รับ
                    ผู้โชคดีจะต้องเป็นผู้รับผิดชอบค่าใช้จ่ายเอง สอบถามข้อมูล
                    และตรวจสอบเงื่อนไขเพิมเติมได้ที่ .บริษัท ไทยออซัม จำกัด (มหาชน)
                    02-8215545
                  </td>{" "}
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>8. </td>
                  <td>
                    บริษัทฯ เป็นเพียงผู้จัดหาของรางวัลเพื่อรายการชิงโชคนี้เท่านั้น
                    โดยบริษัทฯ มิได้มีส่วนเกี่ยวข้องกับการผลิต และ/หรือการจำหน่าย
                    และ/หรือ การให้บริการ ในของรางวัลที่ระบุไว้แต่อย่างใด ดังนั้น
                    บริษัทฯ จึงไม่ต้องรับผิดชอบในความเสียหาย บุบสลาย ชำรุดบกพร่อง
                    และ/หรือ ในเหตุอื่นใดอันเกิดขึ้นกับของรางวัลทั้งสิ้น
                  </td>{" "}
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>9. </td>
                  <td>
                    พนักงานบริษัท ไทยออซัม จำกัด (มหาชน) ,
                    บริษัทตัวแทนโฆษณาที่เกี่ยวข้อง คณะกรรมการดำเนินรายการและครอบครัว
                    ไม่มีสิทธิ์เข้าร่วมชิงรางวัลในรายการ
                  </td>{" "}
                </tr>
                <tr>
                  <td style={{ verticalAlign: "text-top" }}>10. </td>
                  <td>
                    สำหรับกิจรรมสะสมคะแนนเพื่อลุ้นรับรางวัล
                    ทางทีมจะตรวจสอบข้อมูลเมื่อครบถ้วน และถูกต้องแล้ว
                    จะดำเนินการทยอยจัดส่งของรางวัลไปตามที่อยู่ที่ได้ลงทะเบียน
                    โดยการจัดส่งของรางวัลจะจัดส่งเป็นรอบๆทุก 7 วัน
                    ตลอดจนหมดระยะเวลาของกิจกรรม
                  </td>{" "}
                </tr>
              </table>
            </Col>
          </Row> */}

          <br></br>
        </div>
        <div style={{ height: "100px" }}></div>
      </div>
      {/* <Menu /> */}
    </div>
  );
};

export default Upload_meetandgreet;
