import React from "react";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingToRedirect from "./LoadingToRedirect";
import { useDispatch } from "react-redux";

const UserRoute = ({ children, ...rest }) => {
  // const { user } = useSelector((state) => ({ ...state }));

  //---- UAT ใช้สำหรับจำลองเอา auth ในการ dev บนเครื่อง-------//

  // const data = {
  //   user: {
  //     firstname: "ฟหกฟหก",
  //     gender: "female",
  //     tel: "0844098902",
  //     birthdate: "2023-11-30",
  //     email: "asd@sad.com",
  //     img: "https://profile.line-scdn.net/0hoqbFQl6eMF5PPyNyX9BOIT9vMzRsTmlMal0qb38_bmclCHABZwx2bXI_aWp2X3IAZg53OntqPTpDLEc4UWnMakgPbW9zD38Ka1p4uQ",
  //     token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MTQ3NDQsIm1lbWJlcl9pZCI6NDQ5NiwiY3JlYXRlZF9hdCI6IjIwMjQtMTAtMTQgMjM6Mjg6MzYifQ.eOf2ga-_NwjBZJPRhi_zp8Grfg89KG-gcFWPb3hN8uU"
  //   },
  //   tokens: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MTQ3NDQsIm1lbWJlcl9pZCI6NDQ5NiwiY3JlYXRlZF9hdCI6IjIwMjQtMTAtMTQgMjM6Mjg6MzYifQ.eOf2ga-_NwjBZJPRhi_zp8Grfg89KG-gcFWPb3hN8uU"
  // };

  // localStorage.setItem("auth", JSON.stringify(data));
  // localStorage.setItem("lineAccessToken", "eyJhbGciOiJIUzI1NiJ9.LRLQpc4heOk56ofscm8TaHv0DfNbxpcLIZOx-FeQxJbdGThkCZcJKIn53bNw6zoVQDwpJwYsW0Z0-r0ExbiwmHxHVvmbIU3X-jnFczssHy0rhoCIt9-xq6SCjim4MWPbg3_m6fxZ0LJslTrYEJKw0fYKakUd_T2UTSd5zfJGEEM.SwxOIgCoeRMDXzuYnuoBqQZcxLLFbsv28rjfE-bTFnY")

  // สำหรับไว้เช็คที่อยู่
  // localStorage.setItem("addressStatus", '0')

  //---- UAT ใช้สำหรับหลอกเอา auth ในการ dev บนเครื่อง-------//

  const auth = JSON.parse(window.localStorage.getItem("auth"));



  return auth && auth.user ? <Route {...rest} /> : <LoadingToRedirect />;
};

export default UserRoute;
